














import { Component, Vue } from 'vue-property-decorator'
import IconCircleSearch from '@/components/UI/icons/BadIconsDoNotUse/IconCircleSearch.vue'

@Component({
  name: 'AddMembersNoResults',
  components: {
    IconCircleSearch,
  },
})
export default class AddMembersNoResults extends Vue {
}
